.react-datepicker-popper {
	z-index: 9999 !important;
}

.srh-responsive{
	z-index: 101;
}

.ctb-responsive{
	z-index: 100;
}

.btn-srh-responsive {
	width: 100%;
}

.btn-ddt-responsive {
	width: 100%;
}

.btn-dtc-responsive {
	width: auto
}

.icon-col > .icon-center {
	cursor: pointer;
}

.icon-col > .icon-center > .icon-img {
	height: 35px;
	width: 35px;
}

.icon-col > .icon-center > .icon-title {
	font-size: 11px;
}

.title-pos {
	display: flex;
	justify-content: flex-start;
}

#detail-chart {
	position: relative;
	z-index: 99;
}

#container-table {
	max-width: 100%;
	overflow: hidden;
	z-index: 70;
}

#detail-table {
	overflow-x: auto;
}

#table-salesmanagement {
    width: 100%;
    border-collapse: collapse;
    min-width: 800px;
}

.column-no {
	width: 5%;
	text-align: center;
	font-weight: bold;
	padding: 4px;
	border-bottom: 1px solid gray;
}

.column-module {
	width: 20%;
	text-align: left;
	font-weight: bold;
	padding: 4px;
	border-bottom: 1px solid gray;
}

.column-sales {
	width: 12%;
	text-align: center;
	font-weight: bold;
	padding: 4px;
	border-bottom: 1px solid gray;
}

.column-cost {
	width: 12%;
	text-align: center;
	font-weight: bold;
	padding: 4px;
	border-bottom: 1px solid gray;
}

.column-profit {
	width: 12%;
	text-align: center;
	font-weight: bold;
	padding: 4px;
	border-bottom: 1px solid gray;
}

.column-pprofit {
	width: 12%;
	text-align: center;
	font-weight: bold;
	padding: 4px;
	border-bottom: 1px solid gray;
}

.column-refund {
	width: 13%;
	text-align: center;
	font-weight: bold;
	padding: 4px;
	border-bottom: 1px solid gray;
}

.column-qty {
	width: 8%;
	text-align: center;
	font-weight: bold;
	padding: 4px;
	border-bottom: 1px solid gray;
}

.column-pax {
	width: 8%;
	text-align: center;
	font-weight: bold;
	padding: 4px;
	border-bottom: 1px solid gray;
}

.column-no-tb {
	width: 5%;
	text-align: center;
	font-weight: bold;
	padding: 4px;
}

.column-module-tb {
	width: 20%;
	text-align: left;
	font-weight: bold;
	padding: 4px;
}

.column-sales-tb {
	width: 12%;
	text-align: center;
	font-weight: bold;
	padding: 4px;
}

.column-cost-tb {
	width: 12%;
	text-align: center;
	font-weight: bold;
	padding: 4px;
}

.column-profit-tb {
	width: 12%;
	text-align: center;
	font-weight: bold;
	padding: 4px;
}

.column-pprofit-tb {
	width: 12%;
	text-align: center;
	font-weight: bold;
	padding: 4px;
}

.column-refund-tb {
	width: 13%;
	text-align: center;
	font-weight: bold;
	padding: 4px;
}

.column-qty-tb {
	width: 8%;
	text-align: center;
	font-weight: bold;
	padding: 4px;
}

.column-pax-tb {
	width: 8%;
	text-align: center;
	font-weight: bold;
	padding: 4px;
}

.column-progress {
	width: 100%;
}

@media (min-width: 992px) {
	.btn-srh-responsive {
		width: 75px;
		margin-top: 31px;
	}
	
	.btn-ddt-responsive {
		width: 150px;
		margin-top: 31px;
	}
	
	.btn-dtc-responsive {
		width: 150px;
	}

	.srh-responsive{
		position: sticky;
		top: 48px;
		z-index: 101;
	}
	
	.ctb-responsive{
		position: sticky;
		top: 146px;
		z-index: 100;
	}

	.icon-col > .icon-center > .icon-img {
		height: 100px;
		width: 100px;
	}
	
	.icon-col > .icon-center > .icon-title {
		font-size: 20px;
	}

	.title-pos {
		display: flex;
		justify-content: flex-end;
	}

	#container-table {
		max-width: 100%;
		overflow: hidden;
		z-index: 70;
	}

	#detail-table {
		width: 100%;
	}
}