.btn-srh-responsive {
	width: 100%;
}

.container-table {
	max-width: 100%;
	overflow: hidden;
	z-index: 70;
}

.detail-table {
	overflow-x: auto;
}

#table-categoryrevenue {
	width: 100%;
	border-collapse: collapse;
	min-width: 600px;
}

#table-freqplayer {
	width: 100%;
	border-collapse: collapse;
	min-width: 500px;
}

.column-1 {
	padding: 16px;
	width: 30%;
	border-bottom: 1px solid gray;
}

.column-2 {
	padding: 9px;
	width: 5%;
	border-bottom: 1px solid gray;
}

.column-3 {
	padding: 16px;
	width: 30%;
	border-bottom: 1px solid gray;
}

.column-4 {
	padding: 16px;
	width: 10%;
	border-bottom: 1px solid gray;
}

.column-5 {
	padding: 16px;
	width: 25%;
	text-align: end;
	border-bottom: 1px solid gray;
}

@media (min-width: 992px) {
	.btn-srh-responsive {
		float: right;
		min-width: 84px;
		margin-top: 31px;
	}

	.column-1 {
		padding: 16px;
		width: 30%;
		border-bottom: 1px solid gray;
	}
	
	.column-2 {
		padding: 9px;
		width: 5%;
		border-bottom: 1px solid gray;
	}
	
	.column-3 {
		padding: 16px;
		width: 30%;
		border-bottom: 1px solid gray;
	}
	
	.column-4 {
		padding: 16px;
		width: 10%;
		border-bottom: 1px solid gray;
	}
	
	.column-5 {
		padding: 16px;
		width: 25%;
		text-align: end;
		border-bottom: 1px solid gray;
	}
}