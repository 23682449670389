.Chart-Container {
	height: 275px;
}

.btn-srh-booking {
	width: 100%;
}

#row-button-er {
	width: 100%;
}

.button-er {
	display: flex;
	justify-content: center;
}

@media (min-width: 992px) {
	.Chart-Container {
		height: 350px;
	}

	.btn-srh-booking {
		float: right;
		width: 84px;
		margin-top: 31px;
	}

	#row-button-er {
		width: 40%;
	}

	.button-er {
		justify-content: flex-start;
	}
}